const _temp0 = require("./center.auto.png");
const _temp1 = require("./icon-16.auto.png");
const _temp2 = require("./icon-32.auto.png");
const _temp3 = require("./icon-96.auto.png");
const _temp4 = require("./sticks.auto.png");
const _temp5 = require("./tiles.auto copy.png");
const _temp6 = require("./tiles.auto.png");
const _temp7 = require("./tiles.washizu.auto.png");
const _temp8 = require("./winds.auto.png");
module.exports = {
  "center.auto": _temp0,
  "icon-16.auto": _temp1,
  "icon-32.auto": _temp2,
  "icon-96.auto": _temp3,
  "sticks.auto": _temp4,
  "tiles.auto copy": _temp5,
  "tiles.auto": _temp6,
  "tiles.washizu.auto": _temp7,
  "winds.auto": _temp8
}